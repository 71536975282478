// import Swiper JS
import Swiper, { Pagination, Mousewheel, Autoplay } from 'swiper';

// import Swiper styles
import 'swiper/swiper-bundle.css';

export default (el) => {

	// init Swiper:
	const dotSlider = new Swiper('.dotSlider_slider', {
		modules: [Pagination, Mousewheel, Autoplay],
		slidesPerView: 1,
		centeredSlides: true,
		autoplay: {
			delay: 5000,
		},
		loop: true,
		pagination: {
			el: '.dotSlider_pagination',
			type: 'bullets',
			clickable: true,
		},
	});

	return {
		unmount () {

		}
	}
}
