import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default function (el) {
  const parent = el.parentElement || el.parentNode
  const direction = el.getAttribute('direction') || 'right'
  const onTop = el.hasAttribute('top')
  const self = el.hasAttribute('self')
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: self ?  el : parent,
      start: onTop ? "top top" : "top bottom",
      end: "+=100%",
      scrub: 1
    }
  })

  tl.to(el, {
    y: onTop ? 200 : -350,
    xPercent: direction === 'left' ? 5 : -5
  })

  return {
    unmount () {

    }
  }
}
