import objectFitImages from 'object-fit-images'
import LazyLoad from 'vanilla-lazyload'
import { set } from 'lib/util'
import select from 'select-dom'

const instance = new LazyLoad({
  class_loaded: 'is-loaded',
  threshold: 300,
  callback_finish: () => {
    objectFitImages()
  },
  callback_enter: (image) => {

  },
  callback_loaded: (image) => {
    const onLoad = function () {
      let container = image.parentNode || false
      let sources = select.all('source', container) || false

      if (sources) {
        for (let source of sources) {
          if (source.hasAttribute('data-srcset')) {
            source.setAttribute('srcset', source.getAttribute('data-srcset'))
            source.removeAttribute('data-srcset')
          }
        }
      }

      if (container) {
        set(container, 'is-loaded')
      }

    }

    /* For some reason, onLoad events are not firing on picture elements in Safari. */
    const fallbackEvent = setTimeout(onLoad, 500)

    image.onload = () => {
      onLoad()
      clearTimeout(fallbackEvent)
    }
  }
})

export default (el) => {
  instance.update()
}

