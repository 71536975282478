import Vue from 'vue'
import AppState from 'lib/appState'
import 'modules/dynamic-backgroud/dynamic-backgroud.vue'

export default function (el) {
  const instance = new Vue({
    el,
    data: {
      state: AppState
    }
  })

  return {
    unmount () {
      instance.$destroy()
    }
  }
}
