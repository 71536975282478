import gsap from "gsap"
import select from 'dom-select'
import { set } from 'lib/util'

export default function (el) {
  const dragElement = select('[data-container]', el)
  const dragItems = select.all('.about-us-gallery__slide', el)
  const blobs = select.all('.blob', el)
  const blobsTimeline = gsap.timeline({ yoyo: true, repeat: -1 })
  blobsTimeline.to(blobs[0], { duration: 25, rotation: 180, xPercent: 300, yPercent: 50, ease: 'none' }, '<')
  blobsTimeline.to(blobs[1], { duration: 25, rotation: -180, xPercent: -200, yPercent: -50, ease: 'none' }, '<')
  blobsTimeline.to(blobs[2], { duration: 25, rotation: 2000, xPercent: -400, yPercent: 80, ease: 'none' }, '<')
  blobsTimeline.to(blobs[1], { duration: 25, rotation: 120, xPercent: 200, yPercent: 90, ease: 'none' })
  blobsTimeline.to(blobs[0], { duration: 25, rotation: -360, xPercent: -90, yPercent: 100, ease: 'none' }, '<')
  blobsTimeline.to(blobs[2], { duration: 25, rotation: 240, xPercent: -20, yPercent: 0, ease: 'none' }, '<')

  const draggingAnimation = gsap.timeline()

  const classees  = ['one', 'two', 'three', 'four']
  let lastIndex = 0
  for (const dragItem of dragItems) {
    if (lastIndex >= classees.length) {
      lastIndex = 0
    }

    set(dragItem, classees[lastIndex])
    lastIndex++
  }

  const scrollLimit = dragElement.scrollWidth - window.innerWidth
  draggingAnimation.to(dragElement, { duration: 50, ease: 'none', x: -1 * scrollLimit, yoyo: true, repeat: -1 })

  return {
    unmount () {
    }
  }
}
