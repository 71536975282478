import AOS from 'aos'

const instance = AOS.init({
  duration: 800,
  delay: 50,
  once: true,
  anchorPlacement: 'top-bottom'
})

 export default AOS
