import select from 'dom-select'
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import animateScrollTo from 'animated-scroll-to'
import AOS from 'lib/aos'
import on from 'dom-event'
import device from 'current-device'
import { set, unset } from 'lib/util'

gsap.registerPlugin(ScrollTrigger);

class IntroHero {
  constructor (el) {
    this.el = el
    this.videoEl = select.all('[data-video]', el)
    this.textLayers = select.all('[data-text]', el)
    this.tapCTA = select.all('.intro-hero__tap-hover', el)
    this.scrollTo = select.all('.scroll-to-explore', el)
    // this.playBtn = select('.lead-video__play', this.el)
    this.initEvents();
    this.animatedIntro();
  }

  initEvents () {
    on(this.el, 'touchstart', () => {
      set(this.el, 'is-touching')
    })

    on(this.el, 'touchend', () => {
      unset(this.el, 'is-touching')
    })

    this.scrollTo.length && on(this.scrollTo[0], 'click', (e) => {
      this.scrollToNext()
    })

    for (const textLayer of this.textLayers) {
      on(textLayer, 'mouseenter', () => {
        set(this.el, 'is-touching')
      })
      on(textLayer, 'mouseleave', () => {
        unset(this.el, 'is-touching')
      })
    }
  }

  animatedIntro () {

    this.el.style.height = `${window.innerHeight}px`
    gsap.timeline()
      .from(this.el,{ duration: 2, opacity: 0, delay: 1 })
      .to(this.videoEl,  { filter: 'blur(10px)', duration: 1.5 })
      .to(this.scrollTo, { opacity: 0, y: 20, duration: 1 }, '<')
      .from(this.textLayers, { opacity: 0, scale: 1.1, y: 50, duration: 1.5, filter: 'blur(20px)' })
      // .from(this.playBtn, { opacity: 0, scale: 1.1, duration: 1 })
      // .from(this.tapCTA, { opacity: 0, duration: 1 }, '<')
  }

  destroy () {
    this.timeline.scrollTrigger.kill()
    this.timeline.kill()
  }

  scrollToNext () {
    animateScrollTo(window.innerHeight)
  }
}

export default function (el) {
  const instance = new IntroHero(el)
  return {
    unmount () {
      instance.destroy()
    }
  }
}
