import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger);

(function() {
	const blurProperty = gsap.utils.checkPrefix("filter"),
	      blurExp = /blur\((.+)?px\)/,
	      getBlurMatch = target => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];

	gsap.registerPlugin({
		name: "blur",
		get(target) {
			return +(getBlurMatch(target)[1]) || 0;
		},
		init(target, endValue) {
			let data = this,
			    filter = gsap.getProperty(target, blurProperty),
			    endBlur = "blur(" + endValue + "px)",
			    match = getBlurMatch(target)[0],
			    index;
			if (filter === "none") {
				filter = "";
			}
			if (match) {
				index = filter.indexOf(match);
				endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
			} else {
				endValue = filter + endBlur;
				filter += filter ? " blur(0px)" : "blur(0px)";
			}
			data.target = target;
			data.interp = gsap.utils.interpolate(filter, endValue);
		},
		render(progress, data) {
			data.target.style[blurProperty] = data.interp(progress);
		}
	});
})();

export default (el) => {

	var slider = document.querySelector('.colorTransitionSlider');
	var slides = document.querySelectorAll('.colorTransitionSlider_slide');

	slider.classList.add('is\:ready');

	setTimeout(function() {

		// entire slider
		ScrollTrigger.create({
			trigger: slider,
			start: "top top",
			end: "bottom bottom",
			scrub: true,
			//markers: true,
			pin: '.colorTransitionSlider_content',
			pinSpacing: false,
			id: 'slider-content',
		});


		// each panel
		gsap.utils.toArray(".colorTransitionSlider_slide").forEach((panel, i) => {
			var slideIndex = parseInt(panel.getAttribute('data-slide'));


			// IN - HEADLINE
			gsap.timeline({
				scrollTrigger: {
					trigger: panel,
					start: "top bottom",
					end: "top 10%",
					scrub: true,
					snapTo: 'labels',
					id: 'slide-' + slideIndex
				}
			})
			.fromTo('#colorTransitionSlider_headline_' + slideIndex,
				{ opacity: 0, transform: 'translateY(50vh)' },
				{ opacity: 1, transform: 'translateY(0)' }
			).addLabel('headlineIn');

			// IN - TEXT
			gsap.timeline({
				scrollTrigger: {
					trigger: panel,
					start: "top 33%",
					end: "top top",
					scrub: true,
					snapTo: 'labels',
					id: 'slide-' + slideIndex
				}
			})
			.fromTo('#colorTransitionSlider_text_' + slideIndex,
				{ opacity: 0 },
				{ opacity: 1 },
			).addLabel('textIn');

			// OUT - HEADLINE
			gsap.timeline({
				scrollTrigger: {
					trigger: panel,
					start: "bottom 33%",
					end: "bottom -50%",
					scrub: true,
					snapTo: 'labels',
					id: 'slide-headline-out-' + slideIndex
				}
			})
			.to('#colorTransitionSlider_headline_' + slideIndex,
				{ blur: 4, transform: 'translateY(calc(-100% - 50vh))' }
			).addLabel('headlineOut');

			// OUT - TEXT
			gsap.timeline({
				scrollTrigger: {
					trigger: panel,
					start: "bottom 66%",
					end: "bottom 33%",
					scrub: true,
					snapTo: 'labels',
					id: 'slide-text-out-' + slideIndex
				}
			})
			.to('#colorTransitionSlider_text_' + slideIndex,
				{ opacity: 0 }
			).addLabel('textOut');

			// function getHeadlineYValue() {
			// 	var nextIndex = slideIndex + 1;
			// 	var currentSlide = document.querySelector('#colorTransitionSlider_headline_' + slideIndex);
			// 	var nextSlide = document.querySelector('#colorTransitionSlider_headline_' + nextIndex);
			// 	var yvalue = 0;
			//
			// 	if(nextSlide) {
			// 		console.log(currentSlide.offsetHeight, 'currentSlide offsetHeight');
			// 		console.log(nextSlide.offsetHeight / 2, 'nextSlide offsetHeight / 2');
			//
			// 		yvalue = -1 * (currentSlide.offsetHeight + (nextSlide.offsetHeight / 2));
			// 		console.log(yvalue);
			// 	}
			//
			// 	return yvalue;
			// }

			// ScrollTrigger.create({
			// 	trigger: panel,
			// 	start: "top 75%",
			// 	end: "top top",
			// 	scrub: true,
			// 	//markers: true,
			// 	onEnter: ({progress, direction, isActive}) => headlineLeave(progress, direction, isActive),
			// 	onLeaveBack: ({progress, direction, isActive}) => headlineUnLeave(progress, direction, isActive),
			// 	id: 'headline-' + slideIndex
			// });
			//
			// function headlineLeave(progress, direction, isActive) {
			//
			// 	if(slideIndex > 0) {
			// 		var previousIndex = slideIndex - 1;
			// 		var headline = document.querySelector('#colorTransitionSlider_headline_' + previousIndex);
			// 		var text = document.querySelector('#colorTransitionSlider_text_' + previousIndex);
			// 		var slide = document.querySelector('#colorTransitionSlider_inner_' + previousIndex);
			// 		var top = headline.getBoundingClientRect().top + window.scrollY;
			//
			// 		//text.style.opacity = 0;
			// 		slide.style.position = 'absolute';
			// 		slide.style.top = top + 'px';
			// 		slide.style.transform = 'translateY(0)';
			// 		slide.style.filter = 'blur(4px)';
			// 	}
			// }
			//
			// function headlineUnLeave(progress, direction, isActive) {
			//
			// 	if(slideIndex > 0) {
			// 		var previousIndex = slideIndex - 1;
			// 		var text = document.querySelector('#colorTransitionSlider_text_' + previousIndex);
			// 		var slide = document.querySelector('#colorTransitionSlider_inner_' + previousIndex);
			//
			// 		//text.style.opacity = 1;
			// 		slide.style.position = 'fixed';
			// 		slide.style.top = '50%';
			// 		slide.style.transform = 'translateY(-50%)';
			// 		slide.style.filter = 'blur(0)';
			// 	}
			//
			// }
		});
	}, 100);

	return {
		unmount () {

		}
	}
}
