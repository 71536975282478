import Vue from 'vue'
import AppState from 'lib/appState'
import { set, unset } from 'lib/util'
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import select from 'dom-select'
gsap.registerPlugin(ScrollTrigger)

export default function (el) {
  const introHero = select('.intro-hero')
  const instance = new Vue({
    el,
    data: {
      state: AppState,
      scrollProgress: 0,
      switchLogo: false
    },
    watch: {
      'state.mainMenu': {
        handler () {
          if (this.state.mainMenu) {
            set(document.body, 'MENU-OPEN')
          } else {
            unset(document.body, 'MENU-OPEN')
          }
        }
      }
    },
    mounted () {
      const scrolltarget = select('[logo-switch-trigger]')
      if (!scrolltarget) return null
      const trigger= new ScrollTrigger.create({
        trigger: scrolltarget,
        endTrigger: "footer",
        scrub: true,
        start: "top top",
		end: 'top top',
        onToggle: ({ isActive }) => {
          this.switchLogo = isActive
        }
      })
    }
  })

  return {
    unmount () {
      instance.$destroy()
    }
  }
}
