import Vue from 'vue'
import FOG from 'vanta/dist/vanta.fog.min'

Vue.component('dynamic-backgroud', {
  props: {
    highlightcolor: {
      default: '#82faa5',
      type: String
    },
    midtonecolor: {
      default: '#2c9670',
      type: String
    },
    lowlightcolor: {
      default: '#fa00ce',
      type: String
    },
    basecolor: {
      default: '#fa430a',
      type: String
    }
  },
  methods: {
    init () {

		console.log(this.highlightColor);

      const variables = this
      this.instance = FOG({
        el: this.$el,
        mouseControls: true,
        touchControls: true,
        minHeight: 200.00,
        minWidth: 200.00,
        highlightColor: this.highlightcolor,
        midtoneColor: this.midtonecolor,
        lowlightColor: this.lowlightcolor,
        baseColor: this.basecolor,
        blurFactor: 0.80,
        speed: 2.00,
        zoom: 0.40
      })
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    if (this.instance) this.instance.destroy()
  }
})
