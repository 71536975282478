import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger);

export default (el) => {
	var ourValues = document.querySelector('.ourValues');
	ourValues.classList.add('is\:ready');

	setTimeout(function() {

		ScrollTrigger.create({
			trigger: '.ourValues',
			start: "top top",
			end: "bottom bottom",
			scrub: true,
			//markers: true,
			pin: '.ourValues_headline',
			pinSpacing: false,
			id: 'ourValues_headline'
		});

		ScrollTrigger.create({
			trigger: '.ourValues',
			start: "top top",
			end: "bottom bottom",
			scrub: true,
			//markers: true,
			pin: '.ourValues_title',
			pinSpacing: false,
			id: 'ourValues_title'
		});

		// each panel
		gsap.utils.toArray(".ourValues_group").forEach((panel, i) => {
			var group = parseInt(panel.getAttribute('data-group'));

			// FADE IN - HEADLINE
			gsap.timeline({
				scrollTrigger: {
					trigger: panel,
					start: "top 33%",
					end: "top top",
					scrub: true,
					snapTo: 'labels',
					id: 'headline-' + group + '-in'
				}
			})
			.fromTo('#ourValues_headline_' + group,
				{ opacity: 0 },
				{ opacity: 1 }
			).addLabel('headlineIn');

			// FADE OUT - HEADLINE
			gsap.timeline({
				scrollTrigger: {
					trigger: panel,
					start: "50% top",
					end: "70% top",
					scrub: true,
					snapTo: 'labels',
					id: 'headline-' + group + '-out'
				}
			})
			.to('#ourValues_headline_' + group,
				{ opacity: 0 }
			).addLabel('headlineOut');


			// FADE IN - SPACER
			gsap.timeline({
				scrollTrigger: {
					trigger: panel,
					start: "70% top",
					end: "80% top",
					scrub: true,
					snapTo: 'labels',
					id: 'spacer-' + group + '-in'
				}
			})
			.fromTo('#ourValues_spacer_' + group,
				{ opacity: 0 },
				{ opacity: 1 }
			).addLabel('spacerIn');

			// FADE OUT - SPACER
			gsap.timeline({
				scrollTrigger: {
					trigger: panel,
					start: "80% top",
					end: "90% top",
					scrub: true,
					snapTo: 'labels',
					id: 'spacer-' + group + '-out'
				}
			})
			.to('#ourValues_spacer_' + group,
				{ opacity: 0 }
			).addLabel('spacerOut');

		});


		// Bubble Slow
		gsap.utils.toArray(".ourValues_bubble_slow").forEach((bubble, i) => {

			// Slow Down bubble
			gsap.timeline({
				scrollTrigger: {
					trigger: bubble,
					start: "top bottom",
					end: "bottom top",
					scrub: true,
					marker: true,
					id: 'bubble-slow'
				}
			})
			.fromTo(bubble,
				{ transform: 'translateY(0)' },
				{ transform: 'translateY(200%)' }
			);

		});

		// Bubble Fast
		gsap.utils.toArray(".ourValues_bubble_fast").forEach((bubble, i) => {

			// Slow Down bubble
			gsap.timeline({
				scrollTrigger: {
					trigger: bubble,
					start: "top bottom",
					end: "bottom top",
					scrub: true,
					marker: true,
					id: 'bubble-fast'
				}
			})
			.fromTo(bubble,
				{ transform: 'translateY(0)' },
				{ transform: 'translateY(-200%)' }
			);

		});

	}, 100);

	return {
		unmount () {

		}
	}
}
