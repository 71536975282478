// import Swiper JS
import Swiper, { Pagination, Navigation } from 'swiper';

// import Swiper styles
import 'swiper/swiper-bundle.css';

export default (el) => {

	var id = el.getAttribute('carousel-id');

	// init Swiper:
	const imageCarousel = new Swiper('.imageCarousel', {
		modules: [Pagination, Navigation],
		slidesPerView: 1,
		loop: true,
		autoHeight: true,
		navigation: {
			nextEl: '#swiper-button-next-' + id,
			prevEl: '#swiper-button-prev-' + id,
		},
		pagination: {
			el: '#swiper-pagination-' + id,
			type: 'bullets',
			clickable: true,
		},
	});

	setTimeout(function() {

	}, 100);

	return {
		unmount () {

		}
	}
}
