import Vue from 'vue'

export default function (el) {
  const app = new Vue({
    el,
    data () {
      return {
        currentActive: 0
      }
    }
  })

  return {
    unmount () {
      app.$destroy()
    }
  }
}
