import styles from '../css/main.css';
import Global from './global'
import init from './lib/init'
import select from 'select-dom'
import QS from 'query-string'
import device from 'current-device'
import animateScrollTo from 'animated-scroll-to'
import { on } from 'delegated-events'
import onEvent from 'dom-event'
import AOS from 'lib/aos';

// import Swiper JS
import Swiper, { Pagination, Navigation } from 'swiper';

// import Swiper styles
import 'swiper/swiper-bundle.css';


device.mobile()

document.addEventListener('DOMContentLoaded', () => {
  init().mount()
  const g = new Global();
  g.init();

  on('click', '.js-scroll-to', (event) => {
    event.preventDefault()
    animateScrollTo(document.querySelector(event.currentTarget.getAttribute('href')))
  })

  const links = select.all('[data-mobilelink]')
  // const parsed = QS.parse(location.search)
  for (const link of links) {
    // const href = link.getAttribute('href').split('?')[0]
    // link.setAttribute('href', QS.stringifyUrl({ url: href, query: parsed }))
    if (!device.mobile()) {
      continue
    }

    onEvent(link, 'click', (e) => {
      e.preventDefault()
      window.location = link.getAttribute('data-mobilelink')
    })
  }

  AOS.refresh()
})

window.addEventListener('resize', () => {
  document.querySelector(':root').style
    .setProperty('--vh', window.innerHeight/100 + 'px');
	setVimeoBgs();
});

const modalTriggers = document.querySelectorAll('.js_vimeo-modal');

for (let i = 0; i < modalTriggers.length; i++) {
	modalTriggers[i].addEventListener('click', e => {
		let vimeoId = e.target.getAttribute('data-video-id');
		constructModal(vimeoId);
	});
}

setVimeoBgs(true);
setImageCarousels();

function constructModal(vimeoId) {
	const body = document.querySelector('body');

	body.classList.add('overflow-hidden');

	let modal = document.createElement('div');
	modal.id = 'modal-' + vimeoId;
	modal.classList.add('modal');

	let button = document.createElement('button');
	button.id = 'modal_close-' + vimeoId;
	button.classList.add('modal_close');
	button.setAttribute('data-video-id', vimeoId);
	modal.appendChild(button);

	button.addEventListener('click', e => {
		let vimeoId = e.target.getAttribute('data-video-id');
		closeModal(vimeoId);
	});

	let mask = document.createElement('div');
	mask.id = 'modal_mask-' + vimeoId;
	mask.classList.add('modal_close', 'modal_mask');
	mask.setAttribute('data-video-id', vimeoId);
	modal.appendChild(mask);

	mask.addEventListener('click', e => {
		let vimeoId = e.target.getAttribute('data-video-id');
		closeModal(vimeoId);
	});

	let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
	svg.setAttribute('viewBox', '0 0 14 14');
	button.appendChild(svg);

	var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
	path.setAttribute('fill', 'currentColor');
	path.setAttribute('d', 'M.636 12.374L12.445.565l.99.99-11.81 11.809z');
	svg.appendChild(path);

	var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
	path.setAttribute('fill', 'currentColor');
	path.setAttribute('d', 'M.636 1.626l.99-.99 11.809 11.809-.99.99z');
	svg.appendChild(path);

	let container = document.createElement('div');
	container.classList.add('modal_contaienr', 'mx-auto', 'max-w-1200', 'w-10/12');
	modal.appendChild(container);

	let bgVimeo = document.createElement('div');
	bgVimeo.classList.add('bg-vimeo', 'relative', 'w-full');
	container.appendChild(bgVimeo);

	let bgVimeoPad = document.createElement('div');
	bgVimeoPad.classList.add('bg-vimeo-pad');
	bgVimeo.appendChild(bgVimeoPad);

	let vimeoVideo = document.createElement('iframe');
	vimeoVideo.classList.add('bg-vimeo-frame', 'w-full', 'h-full', 'inset-0', 'absolute');
	vimeoVideo.src = 'https://player.vimeo.com/video/' + vimeoId + '?title=false"';
	vimeoVideo.setAttribute('frameborder', 0);
	vimeoVideo.setAttribute('allow', 'autoplay; fullscreen');
	vimeoVideo.setAttribute('allowfullscreen', true);
	bgVimeo.appendChild(vimeoVideo);

	body.appendChild(modal);

	setVimeoBgs(false);

	let player = new Vimeo.Player(vimeoVideo);

	player.play();
	modal.classList.add('is-ready');

	player.on('ended', function(data) {
		closeModal(vimeoId);
	});
}

function closeModal(vimeoId) {
	const body = document.querySelector('body');

	body.classList.remove('overflow-hidden');

	let modal = document.querySelector('#modal-' + vimeoId);
	modal.classList.remove('is-ready');

	setTimeout(function() {
		modal.remove();
	}, 500);
}

function setVimeoBgs(carousel) {

	// Size our vimeo videos properly.
	const vimeoBgs = document.querySelectorAll('.bg-vimeo');

	for (let i = 0; i < vimeoBgs.length; i++) {

		var frame = vimeoBgs[i].querySelector('.bg-vimeo-frame');
		var player = new Vimeo.Player(frame);

		Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(function(dimensions) {
			var width = dimensions[0];
			var height = dimensions[1];
			var padding = (height / width) * 100;
			var parent = vimeoBgs[i].parentElement;
			var spacer = vimeoBgs[i].querySelector('.bg-vimeo-pad');

			spacer.style.paddingTop =  padding + '%';

			vimeoBgs[i].style.width = '100%';

			// If the video is shorter than the parent container,
			// We need to adjust the width of the video.
			if (vimeoBgs[i].offsetHeight < parent.offsetHeight) {
				var newWidth = (vimeoBgs[i].offsetWidth / vimeoBgs[i].offsetHeight) * parent.offsetHeight;

				// turn it into a percentage.
				newWidth = (newWidth / parent.offsetWidth) * 100;
				vimeoBgs[i].style.width = newWidth + '%';
			}

			if(carousel) {
				// init Swiper:
				const videoCarousel = new Swiper('.videoCarousel', {
					modules: [Pagination, Navigation],
					slidesPerView: 1,
					loop: true,
					autoHeight: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					pagination: {
						el: '.swiper-pagination',
						type: 'bullets',
						clickable: true,
					},
				});
			}
		});
	}
}

function setImageCarousels() {
	// init Swiper:
	const imageCarousel = new Swiper('.imageCarousel', {
		modules: [Pagination, Navigation],
		slidesPerView: 1,
		loop: true,
		autoHeight: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
	});
}
