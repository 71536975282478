import Vue from 'vue'
import 'modules/dynamic-backgroud/dynamic-backgroud.vue'

export default function (el) {
  const instance = new Vue({
    el
  })

  return {
    unmount () {
      instance.$destroy()
    }
  }
}
